@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./../assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./../assets/fonts/Montserrat-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./../assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./../assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./../assets/fonts/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
}

body {
  color: white;
}