@import "../../scss/colors.scss";

.stocky-home {
  padding-top: 80px;
  font-family: Montserrat;
  font-style: normal;
  color: white;

  .home-banner {
    display: flex;
    padding: 100px;
    min-height: 100vh;
    background-image: url(../../assets/png/home-banner.png);
    width: 100vw;
    background-size: cover;

    .image,
    .information {
      width: 50%;
    }

    .information {
      text-align: right;
      font-size: 36px;

      .join {
      }
      .stock {
        font-size: 48px;
        color: #f3b125;
        font-weight: bold;
        margin-bottom: 24px;
      }
      .earn {
      }
      .download {
        margin-top: 24px;
        font-weight: 400;
        span {
          color: #f3b125;
          font-weight: 900;
        }
      }
    }

    .getlink {
      width: 80%;
      float: right;
    }
  }

  .home-joininfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    img {
      width: 173px;
      height: 200px;
    }

    .joininfo-description {
      margin-left: 32px;
      h1 {
        font-size: 60px;
        margin-bottom: 0;
      }
      h2 {
        display: flex;
        align-items: center;
        font-size: 30px;
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 400;
      }

      button {
        font-size: 30px;
      }
    }
  }

  .home-team-participating {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 150px;

    h1 {
      font-size: 40px;
      color: #f3b125;
    }
    h2 {
      color: #f3b125;
      font-size: 56px;
    }

    .home-participants-list {
      display: flex;
      flex-wrap: wrap;

      .participant {
        width: 20%;
        padding: 16px;
        margin-bottom: 50px;
        img {
          width: 125px;
          height: 125px;
        }
      }
    }

    .left-line {
      position: absolute;
      left: 0;
      margin-top: 300px;
      z-index: -1;
    }

    .new-chanllenges {
      position: relative;

      .money-icon {
        position: absolute;
        right: 0;
        top: 0;
      }

      .Succlent-icon {
        position: absolute;
        left: -100px;
        top: 120px;
      }
    }
  }

  .home-how-to-play {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      position: relative;

      .bulb-icon {
        position: absolute;
        top: 40px;
        left: -150px;
      }
    }

    h1 {
      font-size: 72px;
      font-weight: bold;
    }

    .play-list {
      display: flex;
    }

    .how-to-play-card {
      padding: 16px;
      background-color: #1b1b1b;
      width: 250px;
      height: 250px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-weight: bold;
      margin: 16px;
      background: #1b1b1b;
      position: relative;

      .number {
        position: absolute;
        margin-top: -12px;
        margin-left: 16px;
        left: 0;
        top: 0;
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 50%;
        background: linear-gradient(180deg, #df7139 0%, #e03430 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }

      .desc {
        font-size: 26px;
      }

      img {
        width: 110px;
        height: 110px;
      }
    }

    .how-to-play-card::after {
      content: "";
      position: absolute;
      inset: -0.425em;
      top: 10px;
      left: 10px;
      border-radius: 50px;
      filter: blur(0);
      z-index: -1;
    }

    .orange-gredient {
      .number {
        background: $orange-gredient;
      }
    }
    .orange-gredient::after {
      background: $orange-gredient;
    }

    .pink-gredient {
      .number {
        background: $pink-gredient;
      }
    }
    .pink-gredient::after {
      background: $pink-gredient;
    }

    .blue-gredient {
      .number {
        background: $blue-gredient;
      }
    }
    .blue-gredient::after {
      background: $blue-gredient;
    }

    .yellow-gredient {
      .number {
        background: $yellow-gredient;
      }
    }
    .yellow-gredient::after {
      background: $yellow-gredient;
    }
  }

  // home-app-links

  .home-app-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 150px;
    position: relative;

    h1 {
      font-size: 32px;
    }

    .apps-list {
      display: flex;
    }

    .hand,
    .potly,
    .rightLine {
      position: absolute;
    }

    .hand {
      left: 0;
    }

    .potly {
      right: 170px;
      margin-top: -10px;
    }

    .rightLine {
      right: 0;
      top: 0;
    }

    .app-link-card {
      display: flex;
      width: 350px;
      justify-content: center;
      align-items: center;
      background: #1c1920;
      margin: 12px;
      padding: 24px;
      border-radius: 4px;
      border-radius: 8px;

      img {
        width: 50px;
        margin-right: 16px;
      }

      .title {
        font-size: 32px;
        font-weight: bold;
      }

      .desc {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  // home-contactus

  .home-contactus {
    margin: 0 100px;
    .info-card {
      padding: 64px 64px;
      background-color: #1c1920;
      margin-bottom: 32px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 32px;
        margin: 0;
      }
      h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }

    .mail-info {
      display: flex;
      justify-content: center;
      align-items: center;
      .contact,
      .mail {
        font-size: 24px;
        margin: 32px;
        display: flex;
        align-items: center;
        img {
          margin-right: 12px;
        }
      }

      .contact {
        font-weight: 900;
      }
    }
  }
}
