.getLinkInput-component {
  width: 100%;
  display: flex;
  border-radius: 50px;
  position: relative;
  z-index: inherit;
  overflow: hidden;
  border: 1px solid #9e3af4;

  input {
    width: 100%;
    font-size: 32px;
    text-align: center;
    z-index: inherit;
    border-radius: 50px 0 0 50px;
    border: 0;
    background: linear-gradient(
      180deg,
      rgba(220, 220, 220, 0.71) 0%,
      #ffffff 100%
    );
    color: black;

    ::placeholder {
      color: black;
    }
  }

  button {
    width: 250px;
    padding: 24px;
    font-size: 32px;
    z-index: inherit;
    border: 0;
    background: linear-gradient(
      180deg,
      rgba(158, 58, 244, 0.71) 0%,
      #542c7e 100%
    );
    color: white;
  }
}
