.emailInput-component {
  background: #453d4d;
  padding: 8px;
  width: 300px;
  display: flex;
  border-radius: 4px;

  input {
    width: 100%;
    background: #453d4d;
    border: 0;
    color: #bababa;
  }

  button {
    width: 120px;
    background: #9e3af4;
    border-radius: 8px;
    border: 0;
    color: white;
    padding: 8px;
  }
}
