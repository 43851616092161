@import "../../scss/colors.scss";

.stocky-header {
  display: flex;
  background-color: $black;
  border-bottom: #aaa 0.5px solid;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1;
  color: white;

  .logo {
    display: flex;
    flex-direction: column;
    padding: 8px;
    align-items: center;
    img {
      height: 52px;
      width: 52px;
    }
    .stocksy {
      color: $primary;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.25em;
    }
  }

  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    a {
      color: $white;
      margin: 12px;
    }

    .contact-us {
      margin-left: 32px;
    }
  }
}
